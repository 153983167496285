import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import thumb1 from "../../assets/images/video_thumbnails/1.png";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import ic_delete from "../../assets/images/ic_delete.svg";
import ic_edit from "../../assets/images/ic_edit.svg";
import ic_eyes from "../../assets/images/ic_eyes.svg";
import quraan_kareem_cover_9_line from "../../assets/images/ao_deen_seekhein_pamplet.png";

import Modal from "react-bootstrap/Modal";
import {
  postWithAuthCallWithErrorResponse,
  putCallWithAuthTokenWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig, { BASE_URL } from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import { sendAsFormData } from "../commonFun";
import FormLoader from "../FormLoader";
const Products = () => {
  const { sidebar, adminData } = useContext(AppContext);

  const [AddNewGroup, setAddNewGroup] = useState(0);

  const [isImage, setIsImage] = useState("");
  const [imageWidth, setImageWidth] = useState("100%");



  const [isEdit, setIsEdit] = useState("");

  const [productList, setProductList] = useState([]);
  const [productId, setProductId] = useState(0)

  const [addFeature, setAddFeature] = useState(false);
  const handleAddFeatureClose = () => setAddFeature(false);
  const handleAddFeatureShow = () => setAddFeature(true);

  const [addQuraan, setAddQuraan] = useState(false);
  const handleAddQuraanClose = () => setAddQuraan(false);
  const handleAddQuraanShow = () => setAddQuraan(true);

  const [addWorksrhop, setAddWorksrhop] = useState(false);
  const handleAddWorksrhopClose = () => setAddWorksrhop(false);
  const handleAddWorksrhopShow = () => setAddWorksrhop(true);

  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);

  const [MainVideo, setMainVideo] = useState(false);
  const handleMainVideoClose = () => setMainVideo(false);
  const handleMainVideoShow = () => setMainVideo(true);

  const [AddSteps, setAddSteps] = useState(false);
  const handleAddStepsClose = () => setAddSteps(false);
  const handleAddStepsShow = () => setAddSteps(true);

  const [validated, setValidated] = useState(false);
  const [loader, setLoader] = useState(false);
  const [vedioApiList, setvedioApiList] = useState([]);
  const [videoEditData, setVideoEditData] = useState({
    subcategory_media_VideoName_hindi: "",
    subcategory_media_VideoPath_hindi: "",
    subcategory_media_VideoDuaration_hindi: "",
    subcategory_media_VideoName_lit: " ",
    subcategory_media_VideoPath_lit: "",
    subcategory_media_VideoDuaration_lit: " ",
    subcategory_media_VideoName_urdu: "",
    subcategory_media_VideoPath_urdu: "",
    subcategory_media_VideoDuaration_urdu: "",
    subcategory_media_VideoName_english: " ",
    subcategory_media_VideoPath_english: "",
    subcategory_media_VideoDuaration_english: "",
    subcategory_id: 2,
  });

  // Add Product Object
  const [addProductObj, setAddProductObj] = useState({
    assist_title_hindi: "",
    assist_title_urdu: "",
    assist_title_english: "",
    assist_title_lit: "",
    file: "",
    link_one: "",
    assist_option_id: 4,

  });
  console.log("addProductObj", addProductObj);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const addProductDatacopy = { ...addProductObj };
    addProductDatacopy[name] = value;
    setAddProductObj(addProductDatacopy);
  };

  const handleChangeVideoEdit = (event) => {
    const { name, value } = event.target;
    const videoEditDatacopy = { ...videoEditData };
    videoEditDatacopy[name] = value;
    setVideoEditData(videoEditDatacopy);
  };
  const handleSubmit = (event, section) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (section == "updateVideos") {
        updateMediaApi(event);
      }
      if (section == "product") {
        submitAddProduct(event);
      }
    }

    setValidated(true);
  };
  useEffect(() => {
    videoApiList();
  }, []);
  const videoApiList = () => {
    setLoader(true);
    // ?subcategory_id=3&school_workshop_ten_step_id=10
    simpleGetCallWithErrorResponse(
      ApiConfig.GET_MEDIEA + `?subcategory_id=${8}`
    )
      .then((res) => {
        console.log(res);
        if (res.json && res.json?.result === true) {
          setLoader(false);

          setvedioApiList(res.json.media);
          setVideoEditData(res.json.editmedia);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        // setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  const updateMediaApi = (e) => {
    e.preventDefault();
    handleMainVideoClose();
    putCallWithAuthTokenWithErrorResponse(
      ApiConfig.UPDATE_MEDIEA + `?subcategory_id=${8}`,
      JSON.stringify({
        ...videoEditData,
        modified_by_id: adminData.user_id,
      })
    )
      .then((res) => {
        if (res.json && res.json?.result === true) {
          ToastMsg("success", res.json.message);
          videoApiList();
          // tenStepsListApi(addBtn);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  // Product List
  const productListApi = (urldata) => {
    setLoader(true);
    simpleGetCallWithErrorResponse(urldata)
      .then((res) => {
        console.log("response", res.json);
        setLoader(false);
        setProductList(res.json.data);
        // setQuraneKareemList(res.json.data);
        // setOtherBooksList(res.json.data);
      })
      .catch((err) => {
        setLoader(false);
        ToastMsg("error", "something went wrong");
      });
  };

  // Add Product
  const submitAddProduct = (e) => {
    e.preventDefault();
    handleAddQuraanClose(true);
    setLoader(true);

    !isEdit
      ? postWithAuthCallWithErrorResponse(
        ApiConfig.ADD_QURAAN_FORM,
        sendAsFormData(addProductObj)
      )
        .then((res) => {
          setLoader(false);
          if (res.json.result) {
            ToastMsg("success", "Successfully Added");
            productListApi(
              ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${4}`
            );
          } else {
            // ToastMsg("error", "Something Went Wrong")
            productListApi(
              ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${4}`
            );
          }
        })
        .catch((err) => {
          console.log(err);
        })
      : postWithAuthCallWithErrorResponse(
        ApiConfig.UPDATE_ASSIST_DATA,
        sendAsFormData(addProductObj)
      )
        .then((res) => {
          setLoader(false);
          if (res.json.result) {
            ToastMsg("success", "Successfully Updated");
            productListApi(
              ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${4}`
            );
          } else {
            // ToastMsg("error", "Something Went Wrong")
            productListApi(
              ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${4}`
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  // Delete Product
  const deleteProduct = (id) => {
    setProductId(id)
  }

  // Delete Assist 
  const deleteAssistPopUp = () => {
    handleDeleteClose();
    setLoader(true);
    simpleGetCallWithErrorResponse(
      ApiConfig.ASSIST_DELETE_ID + `?assist_main_id=${productId}`
    )
      .then((res) => {
        setLoader(false);
        handleDeleteClose();
        ToastMsg("success", "Successfully Deleted");
        productListApi(
          ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${4}`
        );
        // setQuraneKareemList(res.json.data);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        productListApi(
          ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${4}`
        );
        // ToastMsg("error", "something went wrong");
      })
  };

  useEffect(() => {
    productListApi(
      ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${4}`
    );
  }, []);

  // Get Update Data of Product
  const getUpdateProductId = (id) => {
    simpleGetCallWithErrorResponse(
      ApiConfig.GET_EDIT_DATA + `?assist_main_id=${id}`
    )
      .then((res) => {
        // console.log("response ===========>", res);
        let data = res.json.assist;
        // let assist_record = res.json.assist_data;

        setAddProductObj({
          assist_title_urdu: data.assist_title_urdu,
          assist_title_lit: data.assist_desc_lit,
          assist_title_english: data.assist_title_english,
          file: data.file,
          assist_option_id: 4,
          assist_title_hindi: data.assist_title_hindi,
          link_one: data.link_one,
          option_assis_img: "",
          option_assist_main_id: id,
        });
      })
      .catch((err) => {
        console.log(err);
        // ToastMsg("error", "something went wrong");
      });
  };

  const handleAddQuraanShowClear = () => {
    setIsImage("");
    setAddProductObj({
      assist_title_hindi: "",
      assist_title_urdu: "",
      assist_title_english: "",
      assist_title_lit: "",
      file: "",
      link_one: "",
      assist_option_id: 4,
    })
    setValidated(false);
  }

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Products</label>
        </div>
      </div>

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button className="me-2 bg-danger" type="submit" onClick={deleteAssistPopUp}>
              Delete
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>

      {/* ======================= END DELETE ========================== */}

      <div className="main-wrapper" id="maktab-main">
        <div className="main-all-video ">
          {vedioApiList?.length > 0 && (
            <div className="right">
              <div className="add-btn">
                <button onClick={handleMainVideoShow}>
                  <img src={ic_edit} alt="" />
                </button>
              </div>
            </div>
          )}

          {vedioApiList?.length > 0 ? (
            vedioApiList?.map((videolist, index) => {
              return (
                <div className="outer-main-video row" key={index}>
                  {videolist.sub_category_media.map((subitem, index) => {
                    return (
                      <div className="col-md-3 outer-main-video" key={index}>
                        <h6>
                          {(subitem.language_id === 4 && <span>Hindi</span>) ||
                            (subitem.language_id === 1 && (
                              <span>English</span>
                            )) ||
                            (subitem.language_id === 2 && <span>Urdu</span>) ||
                            (subitem.language_id === 3 && (
                              <span>Tansliteration</span>
                            ))}{" "}
                          Video
                        </h6>
                        <div className="video">
                          <a href={subitem.subcategory_media_VideoPath}
                            target="_blank"
                          >
                            <img src={thumb1} alt="" />
                            <div className="play-btn">
                              <button class="button">
                                <span class="arrow"></span>
                                Play
                              </button>
                            </div>
                          </a>
                          {/* <video controls poster={thumb1}>
                            <source
                              src={subitem.subcategory_media_VideoPath}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video> */}
                        </div>
                        <div className="content">
                          <div className="left">
                            <div className="step-name">
                              <label htmlFor="" className="key">
                                Name:
                              </label>
                              <label htmlFor="" className="value ms-2">
                                {subitem.subcategory_media_VideoName}
                              </label>
                            </div>
                            <div className="duration">
                              <label htmlFor="" className="key">
                                Duration:
                              </label>
                              <label htmlFor="" className="value ms-2">
                                {subitem.subcategory_media_VideoDuaration}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <h5 className="text-center text-danger">No Data Found</h5>
          )}

          <Modal
            show={MainVideo}
            onHide={handleMainVideoClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                noValidate
                validated={validated}
                // onSubmit={(e) => handleSubmit(e,"videos")}
                onSubmit={(e) => handleSubmit(e, "updateVideos")}
              >
                <div className="feature-wrapper">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <Form.Label>English URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_english"
                        value={
                          videoEditData.subcategory_media_VideoPath_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter English URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Hindi URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_hindi"
                        value={videoEditData.subcategory_media_VideoPath_hindi}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Hindi URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Urdu URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_urdu"
                        value={videoEditData.subcategory_media_VideoPath_urdu}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Urdu URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Transliteration URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_lit"
                        value={videoEditData.subcategory_media_VideoPath_lit}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Transliteration URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name English</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_english"
                        value={
                          videoEditData.subcategory_media_VideoName_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name English
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Hindi</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_hindi"
                        value={videoEditData.subcategory_media_VideoName_hindi}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name Hindi
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Urdu</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_urdu"
                        value={videoEditData.subcategory_media_VideoName_urdu}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Transliteration</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_lit"
                        value={videoEditData.subcategory_media_VideoName_lit}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name Urdu
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration English</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_english"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration English
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Hindi</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_hindi"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_hindi
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Hindi
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Urdu</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_urdu"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_urdu
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Urdu
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Transliteration</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_lit"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_lit
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Transliteration
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Group
                        controlId="formBasicPassword"
                      >
                        <Form.Label>English Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for English Video"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Form.Group
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Hindi Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for Hindi Video"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Form.Group
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Urdu Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for Urdu Video"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Form.Group
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Transliteration Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for Transliteration Video"
                        />
                      </Form.Group>
                    </div>
                    <div className="buttons-modal  text-center mt-2  ">
                      <button className="me-2 ">MEDIA UPDATE</button>
                    </div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            {/* <Modal.Footer className="justify-content-center">
           
            </Modal.Footer> */}
          </Modal>
        </div>
        <hr />
        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
          <Nav variant="pills" className="flex-row">
            <Nav.Item>
              <Nav.Link eventKey="1">Products</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="1">
              <div className="feature-lists publication-tab-content row">
                <div className="add-btn">
                  <button onClick={() => {
                    handleAddQuraanShow();
                    setIsEdit(false);
                    handleAddQuraanShowClear();
                  }}>Add +</button>
                </div>

                {
                  loader ?
                    <FormLoader />
                    :
                    <>
                      {
                        productList && productList.length > 0 ?
                          productList.map((card, index) => {
                            return (
                              <div className="col-md-3">
                                <div className="books-card-main">
                                  <div className="btns-top">
                                    {/* <a href="https://shop.deeniyat.com/" target="_blank">
                                  <button className="view">
                                    <img src={ic_eyes} alt="" />
                                  </button>
                                </a> */}
                                    <button className="delete " onClick={() => {
                                      handleDeleteShow();
                                      deleteProduct(card?.option_assist_main_id);
                                    }}>
                                      <img src={ic_delete} alt="" />
                                    </button>
                                    <button onClick={() => {
                                      handleAddQuraanShow();
                                      setIsEdit(true);
                                      setIsImage("edit");
                                      setImageWidth("117px");
                                      getUpdateProductId(
                                        card?.option_assist_main_id
                                      );
                                    }} className="edit">
                                      <img src={ic_edit} alt="" />
                                    </button>
                                  </div>

                                  <div className="inner-wrapper">
                                    <a href="https://shop.deeniyat.com/" className="book-img">
                                      <img
                                        // src={quraan_kareem_cover_9_line}
                                        src={`${BASE_URL}api/public/assist/${card.option_assis_img}`}
                                        alt="" />
                                    </a>
                                    {
                                      card.item.map(cardData => {
                                        return (
                                          <>
                                            <div className="name">{cardData.assist_title}</div>
                                          </>
                                        )
                                      })
                                    }
                                    <div className="name">
                                      {card.link_one}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }) :
                          <>
                            <h5 className="text-center text-danger">No Data Found</h5>
                          </>
                      }
                    </>
                }
              </div>

              <Modal
                show={addQuraan}
                onHide={handleAddQuraanClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>{isEdit ? "Update Product" : "Add Product"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={(e) => {
                      handleSubmit(e, "product");
                    }}
                  >
                    <div className="feature-wrapper">
                      <div className="row">
                        {/* <div className="col-md-12 mb-4">
                          <Form.Label>Product Image</Form.Label>
                          <Form.Control
                            type="file"
                            // value={addProductObj?.file ? addProductObj?.file?.name : ""}
                            onChange={(e) => {
                              setAddProductObj({
                                ...addProductObj,
                                file: e.target.files[0],
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Sabaq Name
                          </Form.Control.Feedback>
                        </div> */}


                        {isImage ? (
                          <>
                            <div>
                              {" "}
                              <Form.Label>Product Image</Form.Label>
                            </div>
                            <div className="col-md-12 mb-4  d-flex">
                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "col-md-12 "
                                    : "col-md-2 "
                                }
                              >
                                <Form.Control
                                  style={{ width: imageWidth }}
                                  type="file"
                                  name="file"
                                  accept="image/png, image/gif, image/jpeg"
                                  // value={addFeatureData.file}
                                  onChange={(e) => {
                                    setAddProductObj({
                                      ...addProductObj,
                                      file: e.target.files[0],
                                    });
                                    setImageWidth("100%");
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Product Image
                                </Form.Control.Feedback>
                              </div>

                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "d-none"
                                    : "col-md-10 "
                                }
                              >
                                <Form.Control
                                  disabled={true}
                                  required
                                  type="text"
                                  name="file"
                                  value={addProductObj.file}
                                  onChange={handleChange}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Product Image
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-4">
                            <Form.Label>Product Image</Form.Label>
                            <Form.Control
                              required
                              type="file"
                              name="file"
                              accept="image/x-png,image/gif,image/jpeg"
                              // value={addFeatureData?.file}
                              onChange={(e) => {
                                setAddProductObj({
                                  ...addProductObj,
                                  file: e.target.files[0],
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Product Image
                            </Form.Control.Feedback>
                          </div>
                        )}



                        <div className="col-md-6 mb-4">
                          <Form.Label>TItle Urdu</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            value={addProductObj?.assist_title_urdu}
                            onChange={(e) => {
                              setAddProductObj({
                                ...addProductObj,
                                assist_title_urdu: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter TItle Urdu
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>TItle Hindi</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            value={addProductObj?.assist_title_hindi}
                            onChange={(e) => {
                              setAddProductObj({
                                ...addProductObj,
                                assist_title_hindi: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter TItle Hindi
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>TItle English</Form.Label>
                          <Form.Control type="text"
                            required
                            value={addProductObj?.assist_title_english}
                            onChange={(e) => {
                              setAddProductObj({
                                ...addProductObj,
                                assist_title_english: e.target.value
                              })
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter TItle English
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>TItle Tansliteration </Form.Label>
                          <Form.Control type="text"
                            required
                            value={addProductObj?.assist_title_lit}
                            onChange={(e) => {
                              setAddProductObj({
                                ...addProductObj,
                                assist_title_lit: e.target.value
                              })
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter TItle Tansliteration
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-12 mb-4">
                          <Form.Label>App Link</Form.Label>
                          <Form.Control type="text"
                            required
                            value={addProductObj?.link_one}
                            onChange={(e) => {
                              setAddProductObj({
                                ...addProductObj,
                                link_one: e.target.value
                              })
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Link
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="buttons-main">
                        {/* <button className="cancel-btn">Cancel</button> */}

                        <button className="submit-btn"
                        // onClick={submitAddProduct}
                        >{isEdit ? "Update" : "Submit"}</button>
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </main>
  );
};

export default Products;
