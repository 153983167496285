import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import thumb1 from "../../assets/images/video_thumbnails/1.png";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link } from "react-router-dom";
import ic_maktab_icon from "../../assets/images/ic_maktab_icon.png";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import SurahFatiha from "../../assets/SurahFatiha.mp3";
import ic_delete from "../../assets/images/ic_delete.svg";
import Form from "react-bootstrap/Form";
import ic_edit from "../../assets/images/ic_edit.svg";
import ic_eyes from "../../assets/images/ic_eyes.svg";
import upload from "../../assets/images/upload.svg";

import Modal from "react-bootstrap/Modal";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import {
  DeleteCallWithErrorResponse,
  multipartPostCallWithErrorResponse,
  PostCallWithErrorResponse,
  putCallWithAuthTokenWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import FormLoader from "../FormLoader";
const DailyPost = () => {
  const { sidebar, adminData } = useContext(AppContext);

  const [dailyPostData, setDailyPostData] = useState({
    urdu_message: "",
    urdu_reference: "",
    urdu_date: "",

    hindi_message: "",
    hindi_reference: "",
    hindi_date: "",

    english_message: "",
    english_reference: "",
    english_date: "",

    translitration_message: "",
    translitration_reference: "",
    translitration_date: "",
  });

  console.log("dailyPostData", dailyPostData);

  // For Perticular Month
  let year = new Date().getFullYear();
  let date = new Date().getDay();

  let handleMonths = (month) => {
    setDailyPostData({
      ...dailyPostData,
      urdu_date: `${year}-${month}-${date < 10 ? 0 : ""}${date}`,
      hindi_date: `${year}-${month}-${date < 10 ? 0 : ""}${date}`,
      english_date: `${year}-${month}-${date < 10 ? 0 : ""}${date}`,
      translitration_date: `${year}-${month}-${date < 10 ? 0 : ""}${date}`,
    });
  };

  const [selectedMonth, setSelectedMonth] = useState(1);
  console.log("selectedMonth", selectedMonth);

  // Months for Daily Post
  const handleMonthClick = (eventKey) => {
    alert("dfgsdjfh");
    console.log("handleMonthClick", eventKey);
    setSelectedMonth(eventKey);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const dailyPostDataCopy = { ...dailyPostData };
    dailyPostDataCopy[name] = value;
    setDailyPostData(dailyPostDataCopy);
  };

  // const handleChange = (event) => {
  //   const { name, value } = event.target;

  //   if (name === "urdu_date" || name === "hindi_date" || name === "english_date" || name === "translitration_date") {
  //     const formattedDate = value.split("-").reverse().join("-");
  //     const dailyPostDataCopy = { ...dailyPostData, [name]: formattedDate };
  //     setDailyPostData(dailyPostDataCopy);
  //   } else {
  //     const dailyPostDataCopy = { ...dailyPostData, [name]: value };
  //     setDailyPostData(dailyPostDataCopy);
  //   }
  // };
  const [AddNewGroup, setAddNewGroup] = useState(0);

  const [addFeature, setAddFeature] = useState(false);
  const handleAddFeatureClose = () => {
    setAddFeature(false);
    setIdEdit("");
    setDailyPostData({
      ...dailyPostData,
      urdu_message: "",
      urdu_reference: "",
      // urdu_date: "",

      hindi_message: "",
      hindi_reference: "",
      // hindi_date: "",

      english_message: "",
      english_reference: "",
      // english_date: "",

      translitration_message: "",
      translitration_reference: "",
      // translitration_date: "",
    });
  };
  const handleAddFeatureShow = () => setAddFeature(true);

  const [addWorksrhop, setAddWorksrhop] = useState(false);
  const handleAddWorksrhopClose = () => setAddWorksrhop(false);
  const handleAddWorksrhopShow = () => setAddWorksrhop(true);

  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);

  const [MainVideo, setMainVideo] = useState(false);
  const handleMainVideoClose = () => setMainVideo(false);
  const handleMainVideoShow = () => setMainVideo(true);

  const [AddDailyPost, setAddDailyPost] = useState(false);
  const handleAddDailyPostClose = () => setAddDailyPost(false);
  const handleAddDailyPostShow = () => setAddDailyPost(true);
  const [idUpdate, setIdUpdate] = useState("");

  const [validated, setValidated] = useState(false);
  console.log("first", validated);
  const handleSubmit = (event, section) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (section === "videos") {
        updateMediaApi(event);
      }
      if (section === "post") {
        addDailyPostApi(event);
      }
    }

    setValidated(true);
  };
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    dialyPostApiList();
  }, [selectedMonth]);

  useEffect(() => {
    videoApiList();
  }, []);

  const [dialyPostList, setDialyPostList] = useState([]);
  const [vedioApiList, setvedioApiList] = useState([]);
  const [videoEditData, setVideoEditData] = useState({
    subcategory_media_VideoName_hindi: "",
    subcategory_media_VideoPath_hindi: "",
    subcategory_media_VideoDuaration_hindi: "",
    subcategory_media_VideoName_lit: " ",
    subcategory_media_VideoPath_lit: "",
    subcategory_media_VideoDuaration_lit: " ",
    subcategory_media_VideoName_urdu: "",
    subcategory_media_VideoPath_urdu: "",
    subcategory_media_VideoDuaration_urdu: "",
    subcategory_media_VideoName_english: " ",
    subcategory_media_VideoPath_english: "",
    subcategory_media_VideoDuaration_english: "",
    subcategory_id: 2,
  });
  const handleChangeVideoEdit = (event) => {
    const { name, value } = event.target;
    const videoEditDatacopy = { ...videoEditData };
    videoEditDatacopy[name] = value;
    setVideoEditData(videoEditDatacopy);
  };
  const dialyPostApiList = () => {
    setLoader(true);
    simpleGetCallWithErrorResponse(
      ApiConfig.DAILY_POST_LIST + `?month=${selectedMonth}`
    )
      .then((res) => {
        console.log("responshai", res);
        if (res.json && res.json?.result === true) {
          setLoader(false);
          setDialyPostList(res.json.posts);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        // setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  const dialyPostApiListEdit = (id) => {
    setLoader(true);
    simpleGetCallWithErrorResponse(
      ApiConfig.DAILY_POST_EDIT + `?group_id=${id}`
    )
      .then((res) => {
        console.log("dialyPostApiListEdit", res);
        // if (res.json && res.json?.result === true) {
        //   setLoader(false);
        //   let data = res.json.dailedit;
        //   let dailyData = res.json.daily_data
        //   const apiDates = dailyData?.map(item => {
        //     return item.date;
        //   });
          
        //   const updatedDates = apiDates.map(date => {
        //     const apiDateObj = new Date(date);
        //     apiDateObj.setDate(apiDateObj.getDate() + 1);
        //     return apiDateObj.toISOString().split("T")[0];
        //   });
         
        //   setDailyPostData({
        //     urdu_message: data.urdu_desc,
        //     urdu_reference: dailyData[0]?.reference,
        //     urdu_date: updatedDates[0],

        //     hindi_message: data.hindi_desc,
        //     hindi_reference: dailyData[2]?.reference,
        //     hindi_date: updatedDates[2],

        //     english_message: data.english_desc,
        //     english_reference: dailyData[3]?.reference,
        //     english_date: updatedDates[3],

        //     translitration_message: data.translitration_desc,
        //     translitration_reference: dailyData[1]?.reference,
        //     translitration_date: updatedDates[1],
        //   })
        //   // setDialyPostList({

        //   // });
        // } 
        if (res.json && res.json?.result === true) {
          setLoader(false);
          let data = res.json.dailedit;
          let dailyData = res.json.daily_data;
        
          const languageMap = {
            "1": "english_reference",
            "2": "urdu_reference",
            "3": "translitration_reference",
            "4": "hindi_reference"
          };
        
          const dateMap = {
            "1": "english_date",
            "2": "urdu_date",
            "3": "translitration_date",
            "4": "hindi_date"
          };
        
          const setDailyPostDataObj = {};
        
          dailyData.forEach(item => {
            const languageId = item.language_id;
            const referenceKey = languageMap[languageId];
            const dateKey = dateMap[languageId];
        
            setDailyPostDataObj[referenceKey] = item.reference;
        
            const apiDateObj = new Date(item.date);
            apiDateObj.setDate(apiDateObj.getDate());
            setDailyPostDataObj[dateKey] = apiDateObj.toISOString().split("T")[0];
          });
        
          setDailyPostData({
            ...setDailyPostDataObj,
            urdu_message: data.urdu_desc,
            hindi_message: data.hindi_desc,
            english_message: data.english_desc,
            translitration_message: data.translitration_desc,
          });
        }
        
        else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        // setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const videoApiList = () => {
    setLoader(true);
    simpleGetCallWithErrorResponse(
      ApiConfig.GET_MEDIEA + `?subcategory_id=${10}`
    )
      .then((res) => {
        console.log(res);
        if (res.json && res.json?.result === true) {
          setLoader(false);

          setvedioApiList(res.json.media);
          setVideoEditData(res.json.editmedia);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        // setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  const [idEdit, setIdEdit] = useState("");

  const addDailyPostApi = (e) => {
    e.preventDefault();
    handleAddFeatureClose();

    setLoader(true);
    idEdit === "edit"
      ? putCallWithAuthTokenWithErrorResponse(
        ApiConfig.DAILY_POST_UPDATE + `?group_id=${idUpdate}`,
        JSON.stringify(dailyPostData)
      )
        .then((res) => {
          if (res.json && res.json?.result === true) {
            ToastMsg("success", res.json.message);
            dialyPostApiList();
            handleAddFeatureClose();
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          setLoader(false);

          ToastMsg("error", "something went wrong");
        })
      : PostCallWithErrorResponse(
        ApiConfig.DAILY_POST_ADD,
        JSON.stringify(dailyPostData)
      )
        .then((res) => {
          if (res.json && res.json?.result === true) {
            ToastMsg("success", res.json.message);
            dialyPostApiList();
            handleAddFeatureClose();
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          setLoader(false);

          ToastMsg("error", "something went wrong");
        });
  };

  const dailyPostDeleteApi = () => {
    handleDeleteClose();

    // setIsLoading(true);
    DeleteCallWithErrorResponse(
      ApiConfig.DAILY_POST_DELETE + `?group_id=${idUpdate}`
    )
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.result === true) {
          ToastMsg("success", res.json.message);
          dialyPostApiList();
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        // ToastMsg("error", "something went wrong");
      });
  };
  const updateMediaApi = (e) => {
    e.preventDefault();
    handleMainVideoClose();
    putCallWithAuthTokenWithErrorResponse(
      ApiConfig.UPDATE_MEDIEA + `?subcategory_id=${10}`,
      JSON.stringify({
        ...videoEditData,
        modified_by_id: adminData.user_id,
      })
    )
      .then((res) => {
        if (res.json && res.json?.result === true) {
          ToastMsg("success", res.json.message);
          videoApiList();
          // tenStepsListApi(addBtn);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Daily Post</label>
        </div>
      </div>

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              type="submit"
              onClick={dailyPostDeleteApi}
            >
              Delete
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>

      {/* ======================= END DELETE ========================== */}

      <div className="main-wrapper " id="maktab-main">
        <div className="main-all-video position-relative">
          {vedioApiList?.length > 0 && (
            <div className="right">
              <div className="add-btn">
                <button onClick={handleMainVideoShow}>
                  <img src={ic_edit} alt="" />
                </button>
              </div>
            </div>
          )}

          {vedioApiList?.length > 0 ? (
            vedioApiList?.map((videolist, index) => {
              return (
                <div className="outer-main-video row" key={index}>
                  {videolist.sub_category_media.map((subitem, index) => {
                    return (
                      <div className="col-md-3 outer-main-video" key={index}>
                        <h6>
                          {(subitem.language_id === 4 && <span>Hindi</span>) ||
                            (subitem.language_id === 1 && (
                              <span>English</span>
                            )) ||
                            (subitem.language_id === 2 && <span>Urdu</span>) ||
                            (subitem.language_id === 3 && (
                              <span>Tansliteration </span>
                            ))}{" "}
                          Video
                        </h6>
                        <div className="video">
                          <a
                            href={subitem.subcategory_media_VideoPath}
                            target="_blank"
                          >
                            <img src={thumb1} alt="" />
                            <div className="play-btn">
                              <button class="button">
                                <span class="arrow"></span>
                                Play
                              </button>
                            </div>
                          </a>
                          {/* <img src={play_btn} alt="" className="play-btn w-100"/> */}
                          {/* <video controls poster={thumb1}>
                            <source
                              src={subitem.subcategory_media_VideoPath}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video> */}
                        </div>
                        <div className="content">
                          <div className="left">
                            <div className="step-name">
                              <label htmlFor="" className="key">
                                Name:
                              </label>
                              <label htmlFor="" className="value ms-2">
                                {subitem.subcategory_media_VideoName}
                              </label>
                            </div>
                            <div className="duration">
                              <label htmlFor="" className="key">
                                Duration:
                              </label>
                              <label htmlFor="" className="value ms-2">
                                {subitem.subcategory_media_VideoDuaration}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <h5 className="text-center text-danger">No Data Found</h5>
          )}
          <Modal
            show={MainVideo}
            onHide={handleMainVideoClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                noValidate
                validated={validated}
                // onSubmit={(e) => handleSubmit(e,"videos")}
                onSubmit={(e) => handleSubmit(e, "videos")}
              >
                <div className="feature-wrapper">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <Form.Label>English URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_english"
                        value={
                          videoEditData.subcategory_media_VideoPath_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter English URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Hindi URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_hindi"
                        value={videoEditData.subcategory_media_VideoPath_hindi}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Hindi URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Urdu URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_urdu"
                        value={videoEditData.subcategory_media_VideoPath_urdu}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Urdu URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Transliteration URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_lit"
                        value={videoEditData.subcategory_media_VideoPath_lit}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Transliteration URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name English</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_english"
                        value={
                          videoEditData.subcategory_media_VideoName_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name English
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Hindi</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_hindi"
                        value={videoEditData.subcategory_media_VideoName_hindi}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name Hindi
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Urdu</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_urdu"
                        value={videoEditData.subcategory_media_VideoName_urdu}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Transliteration</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_lit"
                        value={videoEditData.subcategory_media_VideoName_lit}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name Urdu
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration English</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_english"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration English
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Hindi</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_hindi"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_hindi
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Hindi
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Urdu</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_urdu"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_urdu
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Urdu
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Transliteration</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_lit"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_lit
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Transliteration
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>English Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for English Video"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Hindi Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for Hindi Video"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Urdu Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for Urdu Video"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Transliteration Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for Transliteration Video"
                        />
                      </Form.Group>
                    </div>
                    <div className="buttons-modal  text-center mt-2  ">
                      <button className="me-2 ">MEDIA UPDATE</button>
                    </div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            {/* <Modal.Footer className="justify-content-center">
           
            </Modal.Footer> */}
          </Modal>
        </div>
        <hr />
        {/* <Nav.Item>Selected Month: {selectedMonth}</Nav.Item> */}
        <div className="daily-post-main">
          <Tab.Container id="left-tabs-example" defaultActiveKey="1">
            <Nav variant="pills" className="flex-row">
              <Nav.Item>
                <Nav.Link
                  eventKey="1"
                  onClick={() => {
                    setSelectedMonth(1);
                    handleMonths("01");
                  }}
                >
                  January
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="2"
                  onClick={() => {
                    setSelectedMonth(2);
                    handleMonths("02");
                  }}
                >
                  February
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="3"
                  onClick={() => {
                    setSelectedMonth(3);
                    handleMonths("03");
                  }}
                >
                  March
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="4"
                  onClick={() => {
                    setSelectedMonth(4);
                    handleMonths("04");
                  }}
                >
                  April
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="5"
                  onClick={() => {
                    setSelectedMonth(5);
                    handleMonths("05");
                  }}
                >
                  May
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="6"
                  onClick={() => {
                    setSelectedMonth(6);
                    handleMonths("06");
                  }}
                >
                  June
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="7"
                  onClick={() => {
                    setSelectedMonth(7);
                    handleMonths("07");
                  }}
                >
                  July
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="8"
                  onClick={() => {
                    setSelectedMonth(8);
                    handleMonths("08");
                  }}
                >
                  August
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="9"
                  onClick={() => {
                    setSelectedMonth(9);
                    handleMonths("09");
                  }}
                >
                  September
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="10"
                  onClick={() => {
                    setSelectedMonth(10);
                    handleMonths("10");
                  }}
                >
                  October
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="11"
                  onClick={() => {
                    setSelectedMonth(11);
                    handleMonths("11");
                  }}
                >
                  November
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="12"
                  onClick={() => {
                    setSelectedMonth(12);
                    handleMonths("12");
                  }}
                >
                  December
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey={selectedMonth}>
                <div className="feature-lists row g-3">
                  <div className="add-btn">
                    <button onClick={handleAddFeatureShow}>Add +</button>
                  </div>
                  {loader ? (
                    <FormLoader />
                  ) : (
                    <>
                      {dialyPostList && dialyPostList.length > 0 ? (
                        dialyPostList.map((postList, index) => {
                          return (
                            <div className="col-md-4">
                              <div className="feature-card " key={index}>
                                <div className="img justify-content-end">
                                  <div className="add-btn">
                                    <button
                                      className="delete "
                                      onClick={() => {
                                        handleDeleteShow();
                                        setIdUpdate(postList.group_id);
                                      }}
                                    >
                                      <img src={ic_delete} alt="" />
                                    </button>
                                    <button
                                      onClick={() => {
                                        // filterData(card.fb_id);
                                        // EditListApi(card.fb_id);
                                        setIdUpdate(postList.group_id);
                                        setIdEdit("edit");
                                        handleAddFeatureShow();
                                        dialyPostApiListEdit(postList.group_id);
                                      }}
                                    >
                                      <img src={ic_edit} alt="" />
                                    </button>
                                  </div>
                                </div>
                                {postList.posts.map((subitem, index) => {
                                  return (
                                    <div key={index}>
                                      <div className="heading">
                                        <label htmlFor="">Deeni Message</label>
                                      </div>
                                      <p className="content">
                                        {subitem.message}
                                      </p>
                                      <div className="date-ref-dialy">
                                        <p>[{subitem.reference}]</p>
                                        <span>{subitem.date}</span>
                                      </div>
                                      <hr />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <h5 className="text-danger text-center">
                          No Data Found
                        </h5>
                      )}
                    </>
                  )}

                  {/* {dialyPostList?.length > 0 &&
                    dialyPostList?.map((postList, index) => {
                      return (
                        <div className="col-md-4">
                          <div className="feature-card " key={index}>
                            <div className="img justify-content-end">
                              <div className="add-btn">
                                <button
                                  className="delete "
                                  onClick={() => {
                                    handleDeleteShow();
                                    setIdUpdate(postList.group_id);
                                  }}
                                >
                                  <img src={ic_delete} alt="" />
                                </button>
                                <button
                                  onClick={() => {
                                    // filterData(card.fb_id);
                                    // EditListApi(card.fb_id);
                                    setIdUpdate(postList.group_id);
                                    setIdEdit("edit");
                                    handleAddFeatureShow();
                                    dialyPostApiListEdit(postList.group_id);
                                  }}
                                >
                                  <img src={ic_edit} alt="" />
                                </button>
                              </div>
                            </div>
                            {postList.posts.map((subitem, index) => {
                              return (
                                <div key={index}>
                                  <div className="heading">
                                    <label htmlFor="">Deeni Message</label>
                                  </div>
                                  <p className="content">{subitem.message}</p>
                                  <div className="date-ref-dialy">
                                    <p>[{subitem.reference}]</p>
                                    <span>{subitem.date}</span>
                                  </div>
                                  <hr />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })} */}
                </div>
              </Tab.Pane>
              {/* <Tab.Pane eventKey="2">
                <div className="feature-lists row g-3">
                  <div className="add-btn">
                    <button onClick={handleAddFeatureShow}>Add +</button>
                  </div>

                  {dialyPostList?.length > 0 &&
                    dialyPostList?.map((postList, index) => {
                      return (
                        <div className="col-md-4 h-100">
                          <div className="feature-card " key={index}>
                            <div className="img justify-content-end">
                              <div className="add-btn">
                                <button
                                  className="delete "
                                  onClick={() => {
                                    handleDeleteShow();
                                    setIdUpdate(postList.group_id);
                                  }}
                                >
                                  <img src={ic_delete} alt="" />
                                </button>
                                <button
                                  onClick={() => {
                                    // filterData(card.fb_id);
                                    // EditListApi(card.fb_id);
                                    setIdUpdate(postList.group_id);
                                    setIdEdit("edit");
                                    handleAddFeatureShow();
                                    dialyPostApiListEdit(postList.group_id);
                                  }}
                                >
                                  <img src={ic_edit} alt="" />
                                </button>
                              </div>
                            </div>
                            {postList.posts.map((subitem, index) => {
                              return (
                                <div key={index}>
                                  <div className="heading">
                                    <label htmlFor="">Deeni Message</label>
                                  </div>
                                  <p className="content">{subitem.message}</p>
                                  <div className="date-ref-dialy">
                                    <p>[{subitem.reference}]</p>
                                    <span>{subitem.date}</span>
                                  </div>
                                  <hr />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Tab.Pane> */}
            </Tab.Content>
          </Tab.Container>

          <Modal
            show={addFeature}
            onHide={handleAddFeatureClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {idEdit === "edit"
                  ? "Update Daily Message"
                  : "Add Daily Message"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                noValidate
                validated={validated}
                onSubmit={(e) => handleSubmit(e, "post")}
              >
                <div className="feature-wrapper">
                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <Form.Label>Daily Message Urdu</Form.Label>
                      <Form.Control
                        required
                        as="textarea"
                        rows={3}
                        name="urdu_message"
                        value={dailyPostData.urdu_message}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Daily Message Urdu
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Reference Urdu</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="urdu_reference"
                        value={dailyPostData.urdu_reference}
                        onChange={handleChange}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Reference Urdu
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Date Urdu</Form.Label>
                      <Form.Control
                        required
                        type="date"
                        name="urdu_date"
                        value={dailyPostData.urdu_date}
                        onChange={(e) => {
                          handleChange(e);
                          setDailyPostData({
                            ...dailyPostData,
                            urdu_date: e.target.value
                          })
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Select Date Urdu
                      </Form.Control.Feedback>
                    </div>

                    <hr />
                    <div className="col-md-12 mb-4">
                      <Form.Label>Daily Message English</Form.Label>
                      <Form.Control
                        required
                        as="textarea"
                        rows={3}
                        name="english_message"
                        value={dailyPostData.english_message}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Daily Message English
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Reference English</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="english_reference"
                        value={dailyPostData.english_reference}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Reference English
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Date English</Form.Label>
                      <Form.Control
                        required
                        type="date"
                        name="english_date"
                        value={dailyPostData.english_date}
                        onChange={(e) => {
                          handleChange(e);
                          setDailyPostData({
                            ...dailyPostData,
                            english_date: e.target.value
                          })
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Select Date English
                      </Form.Control.Feedback>
                    </div>
                    <hr />
                    <div className="col-md-12 mb-4">
                      <Form.Label>Daily Message Hindi</Form.Label>
                      <Form.Control
                        required
                        as="textarea"
                        rows={3}
                        name="hindi_message"
                        value={dailyPostData.hindi_message}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Daily Message Hindi
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Reference Hindi</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="hindi_reference"
                        value={dailyPostData.hindi_reference}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Reference Hindi
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Date Hindi</Form.Label>
                      <Form.Control
                        required
                        type="date"
                        name="hindi_date"
                        value={dailyPostData.hindi_date}
                        onChange={(e) => {
                          handleChange(e);
                          setDailyPostData({
                            ...dailyPostData,
                            hindi_date: e.target.value
                          })
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Date Hindi
                      </Form.Control.Feedback>
                    </div>
                    <hr />
                    <div className="col-md-12 mb-4">
                      <Form.Label>Daily Message Tansliteration </Form.Label>
                      <Form.Control
                        required
                        as="textarea"
                        rows={3}
                        name="translitration_message"
                        value={dailyPostData.translitration_message}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Daily Message Tansliteration
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Reference Tansliteration </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="translitration_reference"
                        value={dailyPostData.translitration_reference}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Reference Tansliteration
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Date Tansliteration </Form.Label>
                      <Form.Control
                        required
                        type="date"
                        name="translitration_date"
                        value={dailyPostData.translitration_date}
                        onChange={(e) => {
                          handleChange(e);
                          setDailyPostData({
                            ...dailyPostData,
                            translitration_date: e.target.value
                          })
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Date Tansliteration
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="buttons-main">
                    {/* <button className="cancel-btn">Cancel</button> */}
                    {/* <button className="submit-btn">Submit</button> */}

                    <button className="submit-btn">
                      {idEdit == "edit" ? "Update" : "Submit"}
                    </button>
                  </div>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </main>
  );
};

export default DailyPost;
